import { FC, PropsWithChildren } from "react";

import { Box, Container, Flex, useBreakpointValue } from "@chakra-ui/react";

import { ICarBrandItem } from "@components/VehicleBrand/types";
import { CSS_VARIABLE } from "@utils/constants";
import { useIsMobile } from "@utils/hooks/hooks";
import { hiddenScrollbarStyles } from "@utils/scrollbarUtils";
import { ICarBrand } from "features/types";

import { VehicleBrandCard } from "./VehicleBrandCard";

interface IVehicleBrandSelectorProps {
  carBrandItems: ICarBrandItem[];
  isSingleLine?: boolean;
  isLink?: boolean;
  onVehicleBrandSelect?: (vehicleBrand: ICarBrand | undefined) => void;
  /**
   * Full object or vehicle brand code.
   *
   * NOTE: Is brand selected checked by brand code.
   */
  selectedVehicleBrand?: ICarBrand | string;
  bgColor?: string;
  isAlwaysSticky?: boolean;
  isStickToTop?: boolean;
}

export const VehicleBrandSelector: FC<PropsWithChildren<IVehicleBrandSelectorProps>> = ({
  carBrandItems,
  onVehicleBrandSelect,
  selectedVehicleBrand,
  isSingleLine,
  children,
  isLink = true,
  bgColor,
  isAlwaysSticky = true,
  isStickToTop = false,
}) => {
  const isMobile = useIsMobile();
  const isVehicleBrandSelected = !!selectedVehicleBrand;
  const isPositionSticky = useBreakpointValue({
    base: isVehicleBrandSelected,
    lg: isVehicleBrandSelected || isAlwaysSticky,
  });

  const isCurrentSelectedBrand = (vehicleBrand: ICarBrand) => {
    if (typeof selectedVehicleBrand === "string") {
      return selectedVehicleBrand === vehicleBrand.voltasMappingName;
    }

    return selectedVehicleBrand?.voltasMappingName === vehicleBrand.voltasMappingName;
  };

  const handleBrandClick = (vehicleBrand: ICarBrand) => {
    if (onVehicleBrandSelect) {
      onVehicleBrandSelect(isCurrentSelectedBrand(vehicleBrand) ? undefined : vehicleBrand);
    }
  };

  return (
    <Box
      data-test-id="vehicleBrandSelector"
      bg={bgColor ?? "gray.800"}
      overflowX="auto"
      sx={isStickToTop ? undefined : hiddenScrollbarStyles}
      position={isPositionSticky ? "sticky" : "static"}
      top={`var(${CSS_VARIABLE.MAIN_NAV_HEIGHT}, ${isStickToTop ? 0 : 80}px)`}
      zIndex={10}
      py={selectedVehicleBrand || isSingleLine ? { base: 3, md: 4 } : { base: 10, md: 14 }}
    >
      <Container maxW="container.xl" px={4}>
        <Flex
          gap={{ base: 4, md: 8 }}
          flexWrap={isSingleLine || isVehicleBrandSelected ? "nowrap" : "wrap"}
          justify={isSingleLine || isVehicleBrandSelected ? "space-between" : "center"}
          data-expanded={isVehicleBrandSelected}
        >
          {carBrandItems.map((vehicle) => (
            <VehicleBrandCard
              isLink={isLink}
              key={vehicle.code}
              vehicle={vehicle}
              isSelected={isCurrentSelectedBrand(vehicle)}
              onVehicleBrandClick={handleBrandClick}
              isSmallCard={isSingleLine || isVehicleBrandSelected || isMobile}
            />
          ))}
        </Flex>
        {children}
      </Container>
    </Box>
  );
};
